.heatMapMonthShrink {
  color: #fff; }

.legend {
  color: #fff !important;
  font-weight: normal !important; }

.heatMapGridItem {
  background-color: #424242 !important;
  border-color: #424242 !important; }

#details-card * {
  box-sizing: content-box !important; }

#details-card {
  box-sizing: content-box !important; }

/*
const first = "#1EB980";
const second = "#045D56";
const third = "#FF6859";
const fourth = "#FFCF44";
*/
#worked .colorScale0 {
  background-color: #ddd; }

#worked .colorScale1 {
  background-color: #b6fff2; }

#worked .colorScale2 {
  background-color: #37efba; }

#worked .colorScale3 {
  background-color: #1eb980; }

#worked .colorScale4 {
  background-color: #007d51; }

/*#worked .colorScale1{background-color: rgb(002, 184, 184);}
#worked .colorScale2{background-color: rgb(002, 146, 146);}
#worked .colorScale3{background-color: rgb(001, 107, 107);}
#worked .colorScale4{background-color: rgb(001, 068, 068);}*/
#license .colorScale0 {
  background-color: #ddd; }

#license .colorScale1 {
  background-color: #8bd8d9; }

#license .colorScale2 {
  background-color: #15d1c6; }

#license .colorScale3 {
  background-color: #00aea4; }

#license .colorScale4 {
  background-color: #045d56; }

/*
#license .colorScale0{background-color: #ddd;}
#license .colorScale1{background-color: rgb(150, 134, 184);}
#license .colorScale2{background-color: rgb(150, 96, 146);}
#license .colorScale3{background-color: rgb(150, 57, 107);}
#license .colorScale4{background-color: rgb(150, 018, 068);}
*/
#reference .colorScale0 {
  background-color: #ddd; }

#reference .colorScale1 {
  background-color: #ffb3a6; }

#reference .colorScale2 {
  background-color: #ff6859; }

#reference .colorScale3 {
  background-color: #ff0600; }

#reference .colorScale4 {
  background-color: #b50000; }

/*
#reference .colorScale1{background-color: rgb(150, 184, 184);}
#reference .colorScale2{background-color: rgb(150, 146, 146);}
#reference .colorScale3{background-color: rgb(150, 107, 107);}
#reference .colorScale4{background-color: rgb(150, 068, 068);}*/
#balance .colorScale0 {
  background-color: #ddd; }

#balance .colorScale1 {
  background-color: #ffebb0; }

#balance .colorScale2 {
  background-color: #ffdc78; }

#balance .colorScale3 {
  background-color: #ffbc2b; }

#balance .colorScale4 {
  background-color: #ff8500; }

/*

#balance .colorScale0{background-color: #ddd;}
#balance .colorScale1{background-color: rgb(150, 184, 134);}
#balance .colorScale2{background-color: rgb(150, 146, 96);}
#balance .colorScale3{background-color: rgb(150, 107, 57);}
#balance .colorScale4{background-color: rgb(150, 068, 018);}
*/
