.vertical-timeline-element--contract .vertical-timeline-element-content {
  border-top: 3px solid #f75500;
  /*background: $md-dark-theme-background-color !important; */
  background: #424242; }

.contract-element-text {
  color: #fff !important; }

@media only screen and (min-width: 1170px) {
  .vertical-timeline-element-icon {
    width: 40px;
    height: 40px;
    top: 15px;
    margin-left: -20px; }
  .vertical-timeline:before {
    margin-left: 0px; }
  .vertical-timeline-element-content {
    width: 46%; } }
