.expandable-card-title > h2 {
  width: 100%; }

.expandable-title-bad {
  color: #FF6859 !important;
  margin-bottom: 0px !important;
  float: right; }

.expandable-title-good {
  color: #4caf50 !important;
  margin-bottom: 0px !important;
  float: right; }
