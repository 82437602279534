.help {
  border-top: 0.2rem solid !important;
  border-bottom: 0.2rem solid !important;
  border-color: #4caf50;
  color: #4caf50;
  margin-bottom: 2rem;
  padding-bottom: 2rem;
  padding-top: 2rem; }

.help-title {
  color: #4caf50; }

.help-prop {
  font-size: 1.125rem;
  color: #e6e6e6; }
