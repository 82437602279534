.rbc-toolbar button {
  border-radius: 0;
  border-color: #4caf50;
  color: #4caf50; }

.rbc-active {
  background-color: #4caf50 !important;
  color: #fff !important;
  border-color: #4caf50 !important; }

.rbc-toolbar-label {
  color: #fff; }

.rbc-header {
  color: #fff; }

.rbc-date-cell {
  color: #fff; }

.rbc-off-range-bg {
  background: #424242; }

.rbc-today {
  background: #303030;
  border: 1px solid #4caf50 !important; }

.rbc-event {
  border-radius: 0; }

.rbc-label {
  color: #fff; }

/* Stop eyes from bleeding */
.rbc-time-view {
  border-color: rgba(221, 221, 221, 0.7); }

.rbc-time-header-content {
  border-color: rgba(221, 221, 221, 0.7); }

.rbc-time-content {
  border-top-color: rgba(221, 221, 221, 0.7); }

.rbc-time-header .rbc-overflowing {
  border-color: rgba(221, 221, 221, 0.7);
  border-right-color: rgba(221, 221, 221, 0.7); }

.rbc-timeslot-group {
  border-color: rgba(221, 221, 221, 0.7); }

.rbc-day-slot .rbc-time-slot {
  border-color: rgba(221, 221, 221, 0.5);
  border-top-color: rgba(221, 221, 221, 0.5); }

/* Event classes */
.secondary {
  background: #f75500;
  color: #fff; }

.orange-50 {
  background: #FFD7D0;
  color: rgba(0, 0, 0, 0.87); }

.orange-300 {
  background: #FF6859;
  color: rgba(0, 0, 0, 0.87); }

.yellow-300 {
  background: #FFCF44;
  color: rgba(0, 0, 0, 0.87); }

.yellow-500 {
  background: #ffac12;
  color: rgba(0, 0, 0, 0.87); }

.purple-50 {
  background: #decaf7;
  color: rgba(0, 0, 0, 0.87); }

.purple-200 {
  background: #B15DFF;
  color: rgba(0, 0, 0, 0.87); }

.blue-200 {
  background: #72DEFF;
  color: rgba(0, 0, 0, 0.87); }

.blue-700 {
  background: #0082FB;
  color: #fff; }

.green-300 {
  background: #37EFBA;
  color: rgba(0, 0, 0, 0.87); }

.green-700 {
  background: #007D51;
  color: #fff; }

.teal-700 {
  background: #045D56;
  color: #fff; }
