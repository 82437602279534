.sign-in-button {
  display: block;
  margin: 0 auto; }

.kv-logo {
  width: 100%;
  max-width: 400px;
  max-height: 400px;
  display: block;
  margin: 0 auto; }

.login {
  padding-top: 90px;
  padding-bottom: 10px;
  width: 100%;
  height: 100vh;
  background-color: #303030;
  font-size: 1.125rem;
  line-height: 1.3333333333;
  color: #fff; }

.login__heading {
  margin-bottom: 36px;
  font-size: 30px;
  font-weight: 300;
  text-align: center; }
